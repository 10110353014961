import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Categories {
  // POST
  async create(establishmentID, sectionID, fieldParams) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        section_id: sectionID,
        category_active: true,
        ...fieldParams,
      });

      message.success("Категорію створено");
    } catch (error) {
      message.error("Помилка при створенні категорії");
    }
  }

  // PUT
  async update(establishmentID, id, fieldParams) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await update(updateListRef, fieldParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // DELETE
  async delete(establishmentID, id, hasSingleDelete = true) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await remove(deleteListRef);

      if (hasSingleDelete) {
        message.success("Категорію видалено");
      }
    } catch (error) {
      if (hasSingleDelete) {
        message.error("Помилка при видаленні категорії");
      }
    }
  }

  // PUT
  async activateDeactivate(establishmentID, id, activateDeactivateParams) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // PUT
  async reorder(establishmentID, updatedItems) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories`,
      );

      await update(createListRef, updatedItems);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка при оновлені");
    }
  }
}
