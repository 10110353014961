import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { StoreContext } from "./contexts/Store";
import mainStore from "../src/stores/Main";
import DataLoader from "./components/DataLoader";
import { FeaturesNavigation } from "./features";

function App() {
  return (
    <StoreContext.Provider value={mainStore}>
      <div className="App">
        <Provider store={store}>
          <Router>
            <DataLoader />
            <Switch>
              <Route path="/" component={FeaturesNavigation} />
            </Switch>
          </Router>
        </Provider>
      </div>
    </StoreContext.Provider>
  );
}

export default App;
