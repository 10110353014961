import { Button, Modal } from "antd";
import Countdown from "antd/es/statistic/Countdown";
import React from "react";
import PropTypes from "prop-types";

function ModalToDelete(props) {
  const {
    customTrigger,
    showModal,
    triggerButtonText,
    isOpen,
    onOk,
    onCancel,
    isDisabled,
    onFinish,
    timer,
    title,
    description,
  } = props;

  return (
    <>
      {!!customTrigger ? (
        customTrigger
      ) : (
        <Button className="mr-2" onClick={showModal}>
          {triggerButtonText}
        </Button>
      )}
      <Modal
        visible={isOpen}
        width={420}
        onOk={onOk}
        onCancel={onCancel}
        cancelText="Відмінити"
        okType="danger"
        okText={
          <div className="d-flex">
            {isDisabled && (
              <Countdown
                value={timer}
                format="ss"
                onFinish={onFinish}
                valueStyle={{
                  fontSize: "14px",
                  marginRight: "6px",
                  color: "#d0d4d7",
                }}
              />
            )}
            Видалити
          </div>
        }
        okButtonProps={{
          disabled: isDisabled,
        }}
      >
        {title && (
          <h4 className="mb-0" style={{ color: "red" }}>
            {title}
          </h4>
        )}
        {description && <h5 className="mb-0">{description}</h5>}
      </Modal>
    </>
  );
}

ModalToDelete.propTypes = {
  customTrigger: PropTypes.object,
  showModal: PropTypes.func,
  triggerButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onFinish: PropTypes.func,
  timer: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
};

ModalToDelete.defaultProps = {
  timer: null,
  title: null,
  description: null,
};

export default ModalToDelete;
