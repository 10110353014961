import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  AUTH_PREFIX_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  RESET_PASSWORD_PATH,
} from "./constants";
import { Loading } from "../../components/shared-components";

function AuthNavigation() {
  const LoginEmail = lazy(() => import(`./components/login-email/LoginEmail`));
  const RegisterEmail = lazy(
    () => import(`./components/register-email/RegisterEmail`),
  );
  const ResetPassword = lazy(
    () => import(`./components/reset-password/ResetPassword`),
  );

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${SIGN_IN_PATH}`}>
          <LoginEmail />
        </Route>
        <Route path={`${SIGN_UP_PATH}`}>
          <RegisterEmail />
        </Route>
        <Route path={`${RESET_PASSWORD_PATH}`}>
          <ResetPassword />
        </Route>
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${SIGN_IN_PATH}`} />
      </Switch>
    </Suspense>
  );
}

export default AuthNavigation;
