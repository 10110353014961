import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import { observer } from "mobx-react";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { BoxWrapper, InfoPanel, MenuDivider } from "../../../shared-components";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";

function MenuSideMenu() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data, isThemeDark } = mainStore.menu;

  // State
  const [openDrawer, setOpenDrawer] = useState(false);

  // Function
  function toggleDrawer(open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  }

  return (
    <>
      <BoxWrapper onClick={() => toggleDrawer(true)}>
        <MenuIcon />
      </BoxWrapper>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onOpen={() => toggleDrawer(true)}
        onClose={() => toggleDrawer(false)}
        swipeAreaWidth={0}
      >
        <Box
          className={classnames("tw-h-full tw-overflow-y-auto tw-p-4", {
            "tw-w-96": isTabletOrLarger,
            "width: tw-w-[90vw]": isMobile,
            "tw-bg-neutral-925": isThemeDark,
          })}
        >
          <Box className="tw-flex tw-justify-between tw-gap-2.5 tw-pb-4">
            <Typography
              component="h2"
              className="!tw-text-2xl !tw-font-bold tw-overflow-hidden tw-break-words"
            >
              {data.establishment_name}
            </Typography>
            <CloseIcon
              className="tw-cursor-pointer"
              onClick={() => toggleDrawer(false)}
            />
          </Box>
          <MenuDivider className="!tw-mb-5" />
          <InfoPanel />
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default observer(MenuSideMenu);
