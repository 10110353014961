// Data Stores
import Categories from "./data/Categories";
import Establishments from "./data/Establishments";
import Menu from "./data/Menu";
import Products from "./data/Products";
import Sections from "./data/Sections";

class MainStore {
  // Data Stores
  categories;
  establishments;
  menu;
  products;
  sections;

  constructor() {
    // Data Stores
    this.categories = new Categories();
    this.establishments = new Establishments(this);
    this.menu = new Menu(this);
    this.products = new Products();
    this.sections = new Sections();
  }

  cleanup() {
    // Data Stores
    this.establishments.cleanup();
    this.menu.cleanup();
  }
}

const mainStore = new MainStore();

export default mainStore;
