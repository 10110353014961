import React, { useContext } from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../../../../contexts/Store";
import { Box, Container, Typography } from "@mui/material";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { MenuProduct } from "./menu-product";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import { MenuDivider } from "../../../shared-components";

function MenuProducts({ categoryID, categoryName, categoryDescription }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data, isThemeDark } = mainStore.menu;
  const products = Object.values(data?.Products ?? {})
    .filter(
      (product) => product.category_id === categoryID && product.product_active,
    )
    .sort((a, b) => a.position - b.position);

  return (
    <Box
      id={categoryID}
      className={classnames({ "tw-mb-5": isTabletOrLarger })}
    >
      {isMobile && <MenuDivider />}
      <Box
        className={classnames({
          "tw-bg-neutral-50": !isThemeDark && isMobile,
          "tw-bg-neutral-950": isThemeDark && isMobile,
        })}
      >
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <Box
            className={classnames("tw-py-5", {
              "tw-bg-neutral-50": !isThemeDark && isTabletOrLarger,
              "tw-bg-neutral-950": isThemeDark && isTabletOrLarger,
              "tw-border-solid": isTabletOrLarger,
              "tw-rounded-t-md": isTabletOrLarger,
              "tw-border-neutral-300": !isThemeDark && isTabletOrLarger,
              "tw-border-neutral-600": isThemeDark && isTabletOrLarger,
              "tw-border": isTabletOrLarger,
              "tw-rounded-b-md": !products.length,
              "tw-px-4": isTabletOrLarger,
            })}
          >
            <Typography
              component="h2"
              className="!tw-text-2xl !tw-font-bold tw-truncate tw-uppercase"
            >
              {categoryName}
            </Typography>
            {categoryDescription && (
              <Typography component="p" className="!tw-font-medium">
                {categoryDescription}
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
      {isMobile && <MenuDivider />}
      {products.map((product, index) => {
        return (
          <MenuProduct
            key={product.id}
            isLast={index === products.length - 1}
            {...product}
          />
        );
      })}
    </Box>
  );
}

MenuProducts.propTypes = {
  categoryID: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryDescription: PropTypes.string,
};

export default observer(MenuProducts);
