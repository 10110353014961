import React, { useContext } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import classnames from "classnames";
import { StoreContext } from "../../../../contexts/Store";
import { observer } from "mobx-react";

function BoxWrapper({ children, onClick, className, style }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark } = mainStore.menu;

  return (
    <Box
      className={classnames(
        "tw-flex",
        "tw-items-center",
        "tw-justify-center",
        "tw-border-solid",
        "tw-border-neutral-400",
        "tw-border",
        "tw-rounded-lg",
        "tw-min-w-12",
        "tw-h-12",
        "tw-px-2",
        "tw-cursor-pointer",
        {
          "tw-bg-white": !isThemeDark,
          "tw-bg-neutral-925": isThemeDark,
          [className]: className,
        },
      )}
      onClick={onClick}
      style={style}
    >
      {children}
    </Box>
  );
}

BoxWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default observer(BoxWrapper);
