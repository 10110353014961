import { makeObservable, observable, action } from "mobx";
import { onValue, push, ref, set, update, remove } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Establishments {
  mainStore;

  // Observable objects
  list = null;

  constructor(mainStore) {
    makeObservable(this, {
      list: observable,
      setList: action,
    });

    this.mainStore = mainStore;
  }

  // GET
  async index() {
    const userID = await getUserId();
    const refValue = ref(database, `${userID}/Establishments`);

    await onValue(refValue, (items) => {
      const dataEstablishments = [];

      items.forEach((item) => {
        const data = item.val();
        dataEstablishments.push({
          ...data,
        });
      });
      this.setList(dataEstablishments);
    });
  }

  // POST
  async create(value) {
    try {
      const userID = await getUserId();
      const postListRef = ref(database, `${userID}/Establishments`);
      const newPostRef = push(postListRef);

      await set(newPostRef, {
        id: newPostRef.key,
        establishment_name: value,
      });

      message.success("Заклад створено");
    } catch (error) {
      message.error("Помилка при створенні закладу");
    }
  }

  // PUT
  async update(id, fieldParams) {
    try {
      const userID = await getUserId();
      const postListRef = ref(database, `${userID}/Establishments/${id}`);

      await update(postListRef, fieldParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // DELETE
  async delete(id) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(database, `${userID}/Establishments/${id}`);

      await remove(deleteListRef);

      message.success("Заклад видалено");
    } catch (error) {
      message.error("Помилка при видаленні закладу");
    }
  }

  setList(value) {
    this.list = value || null;
  }

  // Store Helpers
  cleanup() {
    this.setList(null);
  }
}
