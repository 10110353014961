import React from "react";
import { Box, Typography } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";
import { BoxWrapper, MenuDivider } from "../index";
import { useMediaQuery } from "react-responsive";

function InfoBox({
  icon,
  value,
  description,
  onClick,
  className,
  iconClassName,
  isFooter,
}) {
  // Variables
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <Box
        onClick={onClick}
        className={classnames("tw-flex", "tw-items-center", {
          [className]: className,
        })}
      >
        <BoxWrapper className={iconClassName}>{icon}</BoxWrapper>
        <Box className="tw-pl-3">
          {description && (
            <Typography
              component="pre"
              className="!tw-font-semibold tw-whitespace-pre-wrap !tw-text-sm tw-opacity-50"
            >
              {description}
            </Typography>
          )}
          <Typography
            className="!tw-font-semibold !tw-text-lg tw-whitespace-pre-wrap tw-no-underline"
            component="pre"
          >
            {value}
          </Typography>
        </Box>
      </Box>
      {(isMobile || !isFooter) && <MenuDivider />}
    </>
  );
}

InfoBox.propTypes = {
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  isFooter: PropTypes.bool,
};

export default InfoBox;
