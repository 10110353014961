import React, { useContext } from "react";
import { Divider } from "@mui/material";
import { StoreContext } from "../../../../contexts/Store";
import { observer } from "mobx-react";
import classnames from "classnames";
import PropTypes from "prop-types";

function MenuDivider({ className }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark } = mainStore.menu;

  return (
    <Divider
      className={classnames({
        "!tw-border-neutral-300": !isThemeDark,
        "!tw-border-neutral-600": isThemeDark,
        [className]: className,
      })}
    />
  );
}

MenuDivider.propTypes = {
  className: PropTypes.string,
};

export default observer(MenuDivider);
