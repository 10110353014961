import React from "react";
import { theme } from "../constants";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container, Typography } from "@mui/material";
import { Footer, Header } from "../components";
import { ThemeProvider } from "@mui/material/styles";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";

function OfferAgreement() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Header />
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <Box compoent="section" className="tw-py-8">
            <Typography
              className="tw-text-center !tw-text-2xl !tw-font-bold"
              component="h2"
            >
              ПУБЛІЧНИЙ ДОГОВІР (ОФЕРТА)
            </Typography>
            <Typography
              className="tw-text-center !tw-text-xl !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              на замовлення, купівлю-продаж і доставку товарів
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              Цей договір є офіційною та публічною пропозицією Продавця укласти
              договір купівлі-продажу Товару, представленого на сайті
              <strong> appetiteservices.com</strong>. Даний договір є публічним,
              тобто відповідно до статті 633 Цивільного кодексу України, його
              умови є однаковими для всіх покупців незалежно від їх статусу
              (фізична особа, юридична особа, фізична особа-підприємець) без
              надання переваги одному покупцю перед іншим. Шляхом укладення
              цього Договору покупець в повному обсязі приймає умови та порядок
              оформлення замовлення, оплати товару, доставки товару, повернення
              товару, відповідальності за недобросовісне замовлення та усі інші
              умови договору. Договір вважається укладеним з моменту натискання
              кнопки «Підтвердити Замовлення» на сторінці оформлення замовлення
              і отримання Покупцем від Продавця підтвердження замовлення в
              електронному вигляді.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              1. Визначення термінів
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              1.1. Публічна оферта (далі - «Оферта») - публічна пропозиція
              Продавця, адресована невизначеному колу осіб, укласти з Продавцем
              договір купівлі-продажу товару дистанційним способом (далі -
              «Договір») на умовах, що містяться в цій Оферті.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              1.2. Цифровий Товар або Послуга – об'єкт угоди сторін, який був
              обраний покупцем на сайті Інтернет-сeрвісу, або вже придбаний
              Покупцем у Продавця дистанційним способом.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              1.3. Інтернет-сервіс – сайт Продавця за адресою
              www.appetiteservices.com створений для укладення договорів
              роздрібної та оптової купівлі-продажу на підставі ознайомлення
              Покупця із запропонованим Продавцем описом Товару за допомогою
              мережі Інтернет.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              1.4. Покупець – дієздатна фізична особа, яка досягла 18 років,
              отримує інформацію від Продавця, розміщує замовлення щодо купівлі
              товару, що представлений на сайті Інтернет-сервісу для цілей, що
              не пов'язані зі здійсненням підприємницької діяльності, або
              юридична особа чи фізична особа-підприємець.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              1.5. Продавець – ФОП «Іонов В.Є.» який діє відповідно до чинного
              законодавства України, місцезнаходження якого: м. Суми, вул.
              Охтирська, буд.31
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              2. Предмет Договору
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              2.1. Продавець зобов’язується передати у власність Покупцю
              цифровий Товар, а Покупець зобов’язується оплатити і прийняти
              цифровий Товар на умовах цього Договору.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              2.2. Датою укладення Договору-оферти (акцептом оферти) та моментом
              повного й беззаперечного прийняттям Покупцем умов Договору
              вважається дата заповнення Покупцем форми замовлення, розташованої
              на сайті Інтернет-сервісу, за умови отримання Покупцем від
              Продавця підтвердження замовлення в електронному вигляді. У разі
              необхідності, за бажанням Покупця, Договір може бути оформлений у
              письмовій формі.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              3. Оформлення Замовлення
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.1. Покупець самостійно оформлює замовлення в Інтернет-сервісі,
              або зробивши замовлення електронною поштою чи за номером телефону,
              вказаним в розділі контактів Інтернет-сервісу.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.2. Продавець має право відмовитися від передання замовлення
              Покупцеві у випадку, якщо відомості, вказані Покупцем під час
              оформлення замовлення, є неповними або викликають підозру щодо їх
              дійсності.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.3. При оформленні замовлення на сайті Інтернет-сервісу Покупець
              зобов'язується надати наступну обов’язкову інформацію, необхідну
              Продавцю для виконання замовлення:
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.3.1. прізвище, ім'я Покупця;
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.5. Якщо будь-якої із Сторін договору необхідна додаткова
              інформація, він має право запросити її у іншій Стороні. У разі
              ненадання необхідної інформації Покупцем, Продавець не несе
              відповідальності за надання якісної послуги Покупцю при покупці
              товару в інтернет-сервісі.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.6. При оформленні замовлення через оператора Продавця (п. 3.1.
              Цієї Оферти) Покупець зобов'язується надати інформацію, зазначену
              в п. 3.3. цієї Оферти.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.7. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою
              внесення Покупцем відповідних даних в реєстраційну форму на сайті
              Інтернет-сервісу або при оформленні Замовлення через оператора.
              Після оформлення Замовлення через Оператора дані про Покупця
              вносяться до бази даних Продавця.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.8. Покупець несе відповідальність за достовірність наданої
              інформації при оформленні Замовлення.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              3.9. Укладаючи Договір, тобто акцептуючи умови даної пропозиції
              (запропоновані умови придбання Товару), шляхом оформлення
              Замовлення, Покупець підтверджує наступне:
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              а) Покупець цілком і повністю ознайомлений, і згоден з умовами
              цієї пропозиції (оферти);
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              б) він дає дозвіл на збір, обробку та передачу персональних даних,
              дозвіл на обробку персональних даних діє протягом усього терміну
              дії Договору, а також протягом необмеженого терміну після
              закінчення його дії. Крім цього, укладенням договору Покупець
              підтверджує, що він повідомлений (без додаткового повідомлення)
              про права, встановлених Законом України "Про захист персональних
              даних", про цілі збору даних, а також про те, що його персональні
              дані передаються Продавцю з метою можливості виконання умов цього
              Договору, можливості проведення взаєморозрахунків, а також для
              отримання рахунків, актів та інших документів. Покупець також
              погоджується з тим, що Продавець має право надавати доступ та
              передавати його персональні дані третім особам без будь-яких
              додаткових повідомлень Покупця з метою виконання замовлення
              Покупця. Обсяг прав Покупця, як суб'єкта персональних даних
              відповідно до Закону України "Про захист персональних даних" йому
              відомий і зрозумілий.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              4. Ціна і Доставка Товару
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              4.1 Ціни на Товари та послуги визначаються Продавцем самостійно та
              вказані на сайті Інтернет-сервісу. Всі ціни на Товари та послуги
              вказані на сайті у гривнях з урахуванням ПДВ.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              4.2 Ціни на Товари та послуги можуть змінюватися Продавцем в
              односторонньому порядку залежно від кон'юнктури ринку. При цьому
              ціна окремої одиниці Товару, вартість якої оплачена Покупцем в
              повному обсязі, не може бути змінена Продавцем в односторонньому
              порядку.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              4.6. Зобов'язання Покупця по оплаті Товару вважаються виконаними з
              моменту надходження Продавцю коштів на його рахунок.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              5. Права ті обов’язки Сторін
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.1. Продавець зобов’язаний:
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.1.1. Передати Покупцеві товар у відповідності до умов цього
              Договору та замовлення Покупця.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і
              не надавати доступ до цієї інформації третім особам, за винятком
              випадків, передбачених законодавством та під час виконання
              Замовлення Покупця.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.2. Продавець має право:
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.2.1 Змінювати умови цього Договору, а також ціни на Товари та
              послуги, в односторонньому порядку, розміщуючи їх на сайті
              Інтернет-магазину. Всі зміни набувають чинності з моменту їх
              публікації.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.3 Покупець зобов'язується:
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.3.1 До моменту укладення Договору ознайомитися зі змістом
              Договору, умовами Договору і цінами, запропонованими Продавцем на
              сайті Інтернет-сервісу.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              5.3.2 На виконання Продавцем своїх зобов'язань перед Покупцем
              останній повинен повідомити всі необхідні дані, що однозначно
              ідентифікують його як Покупця, і достатні для доставки Покупцеві
              замовленого цифрового товару.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              6. Повернення Товару
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.1. Покупець має право на повернення Продавцеві цифрового товару
              належної якості, якщо товар не задовольнив його, протягом 14
              (чотирнадцяти) днів, не враховуючи дня купівлі.{" "}
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.2. Вартість товару підлягає поверненню шляхом банківського
              переказу на рахунок Покупця.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.4. Повернення Товару належної якості за адресою Продавця,
              здійснюється за рахунок Покупця та Продавцем Покупцеві не
              відшкодовується.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.5. У разі виявлення протягом встановленого гарантійного строку
              недоліків у цифровому Товарі, Покупець особисто, в порядку та у
              строки, що встановлені законодавством України, має право
              пред'явити Продавцеві вимоги, передбачені Законом України «Про
              захист прав споживачів». При пред’явленні вимог про безоплатне
              усунення недоліків, строк на їх усунення відраховується з дати
              отримання Товару Продавцем в своє розпорядження та фізичного
              доступу до такого Товару.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.6. Розгляд вимог, передбачених Законом України «Про захист прав
              споживачів», провадиться Продавцем за умови надання Покупцем
              документів, передбачених чинним законодавством України. Продавець
              не відповідає за недоліки цифрового Товару, які виникли після його
              передання Покупцеві внаслідок порушення Покупцем правил
              користування або зберігання цифрового Товару, дій третіх осіб або
              непереборної сили.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.7. Покупець не має права відмовитися від цифрового товару
              належної якості, що має індивідуально-визначені властивості, якщо
              зазначений цифровий товар може бути використаний виключно
              Покупцем, який його придбав, (в т.ч. за за бажанням Покупця
              характеристики, функціонал та інше).
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              6.8. Повернення інформаційного товару, у випадках, передбачених
              законом та цим Договором, здійснюється за адресою, вказаною на
              сайті в розділі «Контакти»
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              7. Відповідальність
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              7.1. Продавець не несе відповідальності за шкоду, заподіяну
              Покупцеві або третім особам внаслідок неналежної якості цифрового
              товару.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              7.2. Продавець не несе відповідальності за неналежне, несвоєчасне
              виконання Замовлень і своїх зобов’язань у випадку надання Покупцем
              недостовірної або помилкової інформації.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              7.3. Продавець і Покупець несуть відповідальність за виконання
              своїх зобов'язань відповідно до чинного законодавства України і
              положень цього Договору.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              7.4. Продавець або Покупець звільняються від відповідальності за
              повне або часткове невиконання своїх зобов'язань, якщо невиконання
              є наслідком форс-мажорних обставин як: війна або військові дії,
              землетрус, повінь, пожежа та інші стихійні лиха, що виникли
              незалежно від волі Продавця і / або Покупця після укладення цього
              договору. Сторона, яка не може виконати свої зобов'язання, негайно
              повідомляє про це іншу Сторону.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              8. Конфіденційність і захист персональних даних.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              8.1. Надаючи свої персональні дані на сайті Інтернет-сервісу при
              реєстрації або оформленні Замовлення, Покупець надає Продавцеві
              свою добровільну згоду на обробку, використання (у тому числі і
              передачу) своїх персональних даних, а також вчинення інших дій,
              передбачених Законом України «Про захист персональних даних», без
              обмеження терміну дії такої згоди.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              8.2. Продавець зобов'язується не розголошувати отриману від
              Покупця інформацію. Не вважається порушенням надання Продавцем
              інформації контрагентам і третім особам, що діють на підставі
              договору з Продавцем, в тому числі і для виконання зобов'язань
              перед Покупцем, а також у випадках, коли розкриття такої
              інформації встановлено вимогами чинного законодавства України.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              8.3. Покупець несе відповідальність за підтримання своїх
              персональних даних в актуальному стані. Продавець не несе
              відповідальності за неякісне виконання або невиконання своїх
              зобов'язань у зв'язку з неактуальністю інформації про Покупця або
              невідповідністю її дійсності.
            </Typography>
            <Typography
              className="tw-text-center !tw-text-lg !tw-font-bold !tw-mb-2.5"
              component="h3"
            >
              9. Інші умови
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              9.1. Цей договір укладено на території України і діє відповідно до
              чинного законодавства України.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              9.2. Усі спори, що виникають між Покупцем і Продавцем, вирішуються
              шляхом переговорів. У випадку недосягнення врегулювання спірного
              питання шляхом переговорів, Покупець та/або Продавець мають право
              звернутися за вирішенням спору до судових органів відповідно до
              чинного законодавства України.
            </Typography>
            <Typography component="p" className="!tw-mb-2.5">
              9.3. Продавець має право вносити зміни до цього Договору в
              односторонньому порядку, передбаченому п. 5.2.1. Договору. Крім
              того, зміни до Договору також можуть бути внесені за взаємною
              згодою Сторін в порядку, передбаченому чинним законодавством
              України.{" "}
            </Typography>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default OfferAgreement;
