export const validLink = (typeLink, value) => {
  switch (typeLink) {
    case "map":
      return value.startsWith("https://maps.google.com/") ||
        value.startsWith("https://www.google.com/")
        ? value
        : "https://maps.google.com/?q=" + value;
    case "instagram":
      return value.startsWith("https://www.instagram.com/") || value === ""
        ? value
        : "https://www.instagram.com/" + value;
    case "telegram":
      return value.startsWith("https://t.me/") || value === ""
        ? value
        : "https://t.me/" + value;
    case "facebook":
      return value.startsWith("https://www.facebook.com/") || value === ""
        ? value
        : "https://www.facebook.com/" + value;
    case "viber":
      return value.startsWith("viber://") ||
        value.startsWith("https://viber://") ||
        value === ""
        ? value
        : "viber://chat?number=" + value;
    case "twitter":
      return value.startsWith("https://twitter.com/") || value === ""
        ? value
        : "https://twitter.com/" + value;
    case "whats_app":
      return value.startsWith("https://wa.me/") || value === ""
        ? value
        : "https://wa.me/" + value;
    default:
      return value;
  }
};
