import React, { useContext } from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { StoreContext } from "../../../../../contexts/Store";
import { observer } from "mobx-react";
import { BoxWrapper } from "../../../shared-components";

export const SETTINGS_STORAGE_KEY = "settings";

function MenuThemeSwitcher() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark } = mainStore.menu;

  // Functions
  function handleChangeTheme(isDarkMode) {
    mainStore.menu.setIsThemeDark(isDarkMode);
    localStorage.setItem(
      SETTINGS_STORAGE_KEY,
      JSON.stringify({ is_theme_dark: isDarkMode }),
    );
  }

  return (
    <BoxWrapper onClick={() => handleChangeTheme(!isThemeDark)}>
      {isThemeDark ? <LightModeIcon /> : <DarkModeIcon />}
    </BoxWrapper>
  );
}

export default observer(MenuThemeSwitcher);
