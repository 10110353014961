import { Route, Switch } from "react-router-dom";
import React from "react";
import { MENU_PATH } from "./constants";
import { MenuViews } from "./views";

function MenuNavigation() {
  return (
    <Switch>
      <Route path={MENU_PATH}>
        <MenuViews />
      </Route>
    </Switch>
  );
}

export default MenuNavigation;
