import { Box, Container, Grid, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import React from "react";
import PropTypes from "prop-types";

function QRMenuAdvantageCard({ title, description }) {
  return (
    <Box className="tw-bg-white tw-p-6 md:tw-p-10 tw-rounded-3xl tw-h-full">
      <Typography
        className="!tw-text-2xl !tw-font-semibold !tw-mb-1"
        component="h3"
      >
        {title}
      </Typography>
      <Typography component="p">{description}</Typography>
    </Box>
  );
}

QRMenuAdvantageCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function QRMenuAdvantages() {
  return (
    <Box
      component="section"
      className="md-lg:tw-my-16 max-md-lg:tw-my-8"
      id="qr-menu-advantages"
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Typography
          className="md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-8 max-md-lg:tw-w-full tw-text-center"
          component="h2"
        >
          Переваги використання <span className="tw-text-blue-650">QR </span>
          меню
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md-lg={6}>
            <QRMenuAdvantageCard
              title="Швидкість і зручність"
              description="QR-меню дозволяє гостям швидко отримувати доступ до меню без
              необхідності чекати на офіціанта. Просто відскануйте код, і ви
              відразу зможете переглянути доступні страви та напої."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <QRMenuAdvantageCard
              title="Актуальність інформації"
              description="Легко оновлюйте меню в режимі реального часу. Зміни в цінах чи нові страви можна швидко відобразити, не витрачаючи час на перевидання паперових меню."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <QRMenuAdvantageCard
              title="Економія витрат"
              description="Використання QR-меню зменшує витрати на друк паперових меню. Ви можете зекономити кошти на папері та друкарських послугах, що вигідно для бізнесу."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <QRMenuAdvantageCard
              title="Збільшення кількості замовлень"
              description="Зручний доступ до меню через QR-код може стимулювати клієнтів до частішого замовлення додаткових страв чи напоїв, що збільшує середній чек."
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default QRMenuAdvantages;
