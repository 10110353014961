import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";

const presetColors = [
  "#FF0000",
  "#D0021B",
  "#a8081d",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
];

const ColorPicker = (props) => {
  const { colorChange, color = "" } = props;

  const [visible, setVisible] = useState(false);
  const [pickerColor, setPickerColor] = useState(color);
  const [boxColor, setBoxColor] = useState(color);

  useEffect(() => {
    setBoxColor(color);
    setPickerColor(color);
  }, [color]);

  const onPickerDropdown = () => {
    setVisible(!visible);
  };

  const onColorChange = (value) => {
    const { rgb } = value;
    const rgba = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    setBoxColor(rgba);
    setPickerColor(rgb);
    colorChange(value);
  };

  return (
    <div className="color-picker">
      <div className="color-picker-dropdown">
        <div
          className="color"
          style={{ backgroundColor: boxColor ? boxColor : "#ffffff" }}
          onClick={onPickerDropdown}
        />
      </div>
      {visible && (
        <>
          <div className="color-picker-backdrop" onClick={onPickerDropdown} />
          <SketchPicker
            presetColors={presetColors}
            color={pickerColor}
            onChange={onColorChange}
          />
        </>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  colorChange: PropTypes.func,
};

export default ColorPicker;
