import { Box, Container, Grid, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import { Link as RouterLink } from "react-router-dom";
import { SIGN_UP_PATH } from "../../auth/constants";
import React from "react";

function AboutService() {
  return (
    <Box component="section" id="about-service">
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box
          sx={{ backgroundColor: "#181a22" }}
          className="tw-relative tw-overflow-hidden tw-rounded-[30px] tw-p-6"
        >
          <Box className="tw-absolute tw--top-2/4 tw-left-2/4 tw--translate-x-2/4">
            <Box
              component="img"
              src="/img/home-view-images/blue-dotes.png"
              alt="blue-dotes"
            />
          </Box>
          <Grid container spacing={2} className="tw-relative tw-z-10">
            <Grid item xs={12} md-lg={6}>
              <Box className="tw-flex tw-flex-col tw-justify-center tw-items-start tw-h-full">
                <Typography
                  className="tw-text-white md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-4 max-md-lg:tw-w-full max-md-lg:tw-text-center"
                  component="h2"
                >
                  Ваше <span className="tw-text-blue-650">меню </span>
                  готове за кілька кроків
                </Typography>
                <Typography
                  className="tw-text-white !tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  1. Ми зареєструємо вас в нашій системі
                </Typography>
                <Typography className="tw-text-white !tw-mb-4" component="p">
                  Забезпечимо швидкий старт без зайвих клопотів – просто
                  надішліть нам необхідні дані, і ми все зробимо за вас.
                </Typography>
                <Typography
                  className="tw-text-white !tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  2. Заповнемо меню за вас
                </Typography>
                <Typography className="tw-text-white !tw-mb-4" component="p">
                  Вам не потрібно турбуватися про створення меню – наші фахівці
                  зроблять це замість вас, враховуючи всі ваші побажання.
                </Typography>
                <Typography
                  className="tw-text-white !tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  3. Зробимо для вас пластикові QR коди для кожного столу
                </Typography>
                <Typography className="tw-text-white !tw-mb-4" component="p">
                  Ми виготовимо зручні та довговічні пластикові QR-коди для
                  кожного столу, щоб ваші клієнти могли легко переглядати меню.
                </Typography>
                <RouterLink
                  className="tw-bg-blue-650 !tw-text-white tw-py-2.5 tw-px-8 tw-font-semibold tw-no-underline tw-rounded-3xl max-md-lg:tw-mx-auto"
                  to={`${SIGN_UP_PATH}`}
                >
                  Зареєструватися в системі
                </RouterLink>
              </Box>
            </Grid>
            <Grid item xs={12} md-lg={6}>
              <Box className="tw-flex tw-flex-col tw-items-center before:tw-content-[''] before:tw-bg-blue-650 before:tw-rounded-[30px] before:tw-absolute before:tw-z-[-1] before:tw-bottom-8 before:tw-w-64 before:tw-aspect-square before:tw--rotate-12">
                <Box
                  component="img"
                  className="tw-max-w-64 tw-w-full"
                  src="/img/home-view-images/iPhone-light-theme.png"
                  alt="iPhone"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default AboutService;
