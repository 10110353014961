import { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../contexts/Store";
import { useLocation } from "react-router-dom";
import { getFeatureFromRoute } from "../helpers";
import { ADMIN_PANEL, MENU } from "../constants";

function DataLoader() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Hooks
  const location = useLocation();
  const featureName = getFeatureFromRoute(location);
  const partsURL = location.pathname.split("/");
  const lastIndex = partsURL.length - 1;
  const userID = partsURL[lastIndex];
  const params = new URLSearchParams(location.search);
  const establishmentId = params.get("establishmentId");

  // Function
  async function fetchData() {
    switch (featureName) {
      case ADMIN_PANEL:
        await mainStore.establishments.index();
        break;
      case MENU:
        await mainStore.menu.index(userID, establishmentId);
        break;
      default:
        break;
    }
  }

  // Effects
  useEffect(() => {
    fetchData();
  }, [featureName]);

  return null;
}

export default observer(DataLoader);
