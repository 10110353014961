import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  AboutService,
  Contacts,
  Footer,
  Header,
  Price,
  QRMenu,
  QRMenuAdvantages,
} from "./components";
import ServiceFeatures from "./components/ServiceFeatures";
import { theme } from "./constants";

function HomeView() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Header />
        <QRMenu />
        <AboutService />
        <ServiceFeatures />
        <Contacts />
        <Price />
        <QRMenuAdvantages />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default HomeView;
