import React, { useRef } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import AuthNavigation from "./AuthNavigation";
import { Loading } from "../../components/shared-components";

const backgroundStyle = {
  backgroundImage: "url(/img/others/img-17.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

function Auth() {
  // Refs
  const authContainerRef = useRef();

  // Hooks
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <div
      className="auth-container"
      ref={authContainerRef}
      style={backgroundStyle}
    >
      <AuthNavigation authContainerRef={authContainerRef} />
    </div>
  );
}

export default Auth;
