import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import {
  Box,
  Container,
  Divider,
  Link,
  ListItem,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import Scrollspy from "react-scrollspy";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { SIGN_IN_PATH } from "../../auth/constants";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { OFFER_AGREEMENT_PATH } from "../constants";

function Header() {
  // Variables
  const isDesktopView = useMediaQuery("(min-width:992px)");
  const isMobileOrTabletView = useMediaQuery("(max-width:991.98px)");
  const menuItems = [
    { label: "QR меню", href: "qr-menu" },
    { label: "Про сервіс", href: "about-service" },
    { label: "Можливості", href: "service-features" },
    { label: "Контакти", href: "contacts" },
    { label: "Ціни", href: "price" },
    { label: "Переваги", href: "qr-menu-advantages" },
  ];

  // State
  const [openDrawer, setOpenDrawer] = useState(false);

  // Hooks
  const theme = useTheme();
  const location = useLocation();

  // Functions
  function toggleDrawer(open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  }

  function smoothScroll(target) {
    const element = document.getElementById(target);
    if (!element) {
      return;
    }
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offset = isMobileOrTabletView ? 80 : 110;

    window.scrollTo({
      top: elementPosition - offset,
      behavior: "smooth",
    });

    if (isMobileOrTabletView) {
      toggleDrawer(false);
    }
  }

  // Elements
  const navigationMenu = (
    <Box className="tw-flex md-lg:tw-items-center max-md-lg:tw-items-start max-md-lg:tw-flex-col md-lg:tw-gap-x-8 max-md-lg:tw-gap-y-6">
      {location.pathname !== OFFER_AGREEMENT_PATH && (
        <Scrollspy
          className="tw-flex tw-p-0 tw-m-0 md-lg:tw-items-center max-md-lg:tw-flex-col md-lg:tw-gap-x-6 max-md-lg:tw-gap-y-4"
          items={["qr-menu", "about-service"]}
        >
          {menuItems.map((item, index) => (
            <ListItem key={index} sx={{ padding: 0 }}>
              <Link
                onClick={() => smoothScroll(item.href)}
                underline="none"
                className="!tw-font-semibold !tw-text-black tw-whitespace-nowrap tw-cursor-pointer"
              >
                {item.label}
              </Link>
            </ListItem>
          ))}
        </Scrollspy>
      )}
      <RouterLink
        className="tw-bg-blue-650 !tw-text-white tw-py-2.5 tw-px-8 tw-font-semibold tw-no-underline tw-rounded-3xl"
        to={`${SIGN_IN_PATH}`}
      >
        Увійти
      </RouterLink>
    </Box>
  );

  return (
    <Box
      component="header"
      className="tw-pt-5 tw-sticky tw-top-0 tw-z-50"
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box
          component="nav"
          className="tw-flex tw-items-center tw-justify-between tw-gap-x-8"
        >
          <Link href="/" underline="none" className="tw-flex">
            <Box component="img" src="/img/logo.svg" alt="logo" />
          </Link>
          {isDesktopView && navigationMenu}
          {isMobileOrTabletView && (
            <>
              <MenuIcon
                className="tw-cursor-pointer !tw-text-3xl"
                onClick={() => toggleDrawer(true)}
              />
              <SwipeableDrawer
                anchor="right"
                open={openDrawer}
                onOpen={() => toggleDrawer(true)}
                onClose={() => toggleDrawer(false)}
                swipeAreaWidth={0}
                classes={{ paper: "tw-w-full tw-max-w-80" }}
              >
                <Box className="tw-h-full tw-overflow-y-auto tw-p-4">
                  <CloseIcon
                    className="!tw-flex tw-ml-auto tw-mb-1 tw-cursor-pointer !tw-text-3xl"
                    onClick={() => toggleDrawer(false)}
                  />
                  {navigationMenu}
                </Box>
              </SwipeableDrawer>
            </>
          )}
        </Box>
      </Container>
      <Divider className="!tw-mt-5" />
    </Box>
  );
}

export default Header;
