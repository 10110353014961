import { action, makeObservable, observable } from "mobx";
import { onValue, ref } from "firebase/database";
import { database } from "../../configs";
import { SETTINGS_STORAGE_KEY } from "../../features/menu/views/menu-header/menu-theme-switcher/MenuThemeSwitcher";
import { DEFAULT_MAIN_COLOR } from "../../constants";

export default class Menu {
  mainStore;
  storedSettings;

  // Observable objects
  data = null;
  search = "";
  isThemeDark = false;
  selectedProducts = [];
  selectedPortions = [];

  constructor(mainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      search: observable,
      setSearch: action,
      isThemeDark: observable,
      setIsThemeDark: action,
      selectedProducts: observable,
      setSelectedProducts: action,
      selectedPortions: observable,
      setSelectedPortions: action,
    });

    this.mainStore = mainStore;
    this.storedSettings = JSON.parse(
      localStorage.getItem(SETTINGS_STORAGE_KEY),
    );
  }

  // GET
  async index(userID, establishmentID) {
    if (!userID || !establishmentID) return;

    const refValue = ref(
      database,
      `${userID}/Establishments/${establishmentID}`,
    );

    await onValue(refValue, (data) => {
      this.setData(data.val());
      this.setIsThemeDark();
    });
  }

  setData(value) {
    this.data = value || null;
  }

  setSearch(value) {
    this.search = value || "";
  }

  setIsThemeDark(value) {
    this.isThemeDark =
      value ??
      this.storedSettings?.is_theme_dark ??
      this.data?.settings?.is_theme_dark ??
      false;
  }

  setSelectedProducts(value) {
    this.selectedProducts = value || [];
  }

  setSelectedPortions(value) {
    this.selectedPortions = value || [];
  }

  get mainColor() {
    if (this.isThemeDark) {
      return this.data.settings?.dark_theme_main_color ?? DEFAULT_MAIN_COLOR;
    }

    return this.data.settings?.main_color ?? DEFAULT_MAIN_COLOR;
  }

  // Store Helpers
  cleanup() {
    this.setData(null);
  }
}
